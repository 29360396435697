
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL ?? 'error';
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY ?? 'error';

console.log(supabaseUrl, supabaseKey)
export default createClient(
  'https://lvzbuyryyjlnikfqzwgp.supabase.co', 
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx2emJ1eXJ5eWpsbmlrZnF6d2dwIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzM1MDk1MjMsImV4cCI6MjA0OTA4NTUyM30.iTriJFYsdOIFI2ujnAcDe3FCgOytJJhiwIFYtFtO5mg'
);
        