import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import Card from '@mui/material/Card';
import CSVReader from 'react-csv-reader'
import { Grid, CardContent, TextField, Button, Table, TableHead, TableBody, TableRow, TableCell, TableContainer, Paper } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import supabase from '../../../hooks/supabase';

const calculateCPM = (cost, impressions) => (impressions ? ((cost / impressions) * 1000).toFixed(2) : 0);

const getCampaignSummary = (data) => {
  const totals = {
    cost: 0,
    impressions: 0,
    clicks: 0,
    ctr: 0,
    cpc: 0,
    cpm: 0
  };

  data?.forEach((row) => {
    totals.cost += row.cost;
    totals.impressions += row.impressions;
    totals.clicks += row.clicks;
    totals.ctr += parseFloat(calculateCTR(row.clicks, row.impressions));
    totals.cpc += parseFloat(calculateCPC(row.cost, row.clicks));
    totals.cpm += parseFloat(calculateCPM(row.cost, row.impressions));
  });

  const length = data?.length ?? 1;
  const average = {
    ctr: (totals.ctr / length).toFixed(2),
    cpc: (totals.cpc / length).toFixed(2),
    cpm: (totals.cpm / length).toFixed(2)
  };

  return { totals, average };
};

const calculateCTR = (clicks, impressions) => ((clicks / impressions) * 100).toFixed(2);
const calculateCPC = (cost, clicks) => (cost / clicks).toFixed(2);
const calculateConvRate = (conversions, clicks) => ((conversions / clicks) * 100).toFixed(2);
const calculateROAS = (conversions, cost) => (conversions * 10 / cost).toFixed(2); // Assume average conversion value is $10
const formatNumber = (number) => new Intl.NumberFormat().format(number);
const getSummary = (data) => {
  const totals = {
    cost: 0,
    impressions: 0,
    clicks: 0,
    conversions: 0,
    ctr: 0,
    cpc: 0,
    convRate: 0,
    roas: 0
  };

  data?.forEach((row) => {
    totals.cost += row.cost;
    totals.impressions += row.impressions;
    totals.clicks += row.clicks;
    totals.conversions += row.conversions;
    totals.ctr += calculateCTR(row.clicks, row.impressions);
    totals.cpc += calculateCPC(row.cost, row.clicks);
    totals.convRate += calculateConvRate(row.conversions, row.clicks);
    totals.roas += calculateROAS(row.conversions, row.cost);
  });

  const length = data?.length ?? 1;

  const average = {
    ctr: (totals.ctr / length).toFixed(2),
    cpc: (totals.cpc / length).toFixed(2),
    convRate: (totals.convRate / length).toFixed(2),
    roas: (totals.roas / length).toFixed(2)
  };

  return { totals, average };
};

export default function Charts({ clientId, reload, isAdmin }) {
  const theme = useTheme();

  const [campaignsData, setCampaignsData] = React.useState(undefined);
  const [regions, setRegions] = React.useState();
  const [campaigns, setCampaigns] = React.useState();
  const [form, setForm] = React.useState({
    id: -1,
    name: '',
    cost: 0,
    impressions: 0,
    clicks: 0,
    daily_data: ''
  });

  const [regform, setRegForm] = React.useState({
    id: -1,
    region: '',
    city: '',
    cost: 0,
    impressions: 0,
    clicks: 0,
    conversions: 0
  });

  const getRegions = async () => {
    const { data, error } = await supabase
      .from('regions')
      .select('*')
      .eq('user_id', clientId);

    if (error) {
      console.error(error);
      return;
    }

    setRegions(data);
  }

  React.useEffect(() => {
    getRegions();
  }, [reload, clientId]);

  // Generate the last 60 days in 'YYYY-MM-DD' format
  const getLast60Days = () => {
    const last60Days = [];
    for (let i = 0; i < 60; i++) {
      const date = new Date();
      date.setDate(date.getDate() - i);
      last60Days.push(date.toISOString().split('T')[0]);
    }
    return last60Days;
  };


  const getCampaigns = async () => {
    const { data, error } = await supabase
      .from('campaigns')
      .select('*, campaign_data(*)')
      .eq('user_id', clientId)
      .order('cost', { ascending: false })
      .filter('campaign_data.date', 'gte', new Date(new Date() - 60 * 24 * 60 * 60 * 1000).toISOString()); // Filter by the last 60 days

    if (error) {
      alert(error.message);
      return;
    }

    setCampaigns(data);
    let campaignDailyTotals = {};
    const last60Days = getLast60Days();

    // Initialize daily totals for all last 60 days
    last60Days.forEach(date => {
      campaignDailyTotals[date] = 0; // Initialize total for each date
    });

    // Prepare the result array
    let campaignsResult = [];

    // Loop through each campaign and calculate the daily totals
    data.forEach(campaign => {
      let campaignValues = new Array(60).fill(0); // Initialize an array to store the values for the last 60 days

      // Loop through each campaign's campaign_data
      campaign.campaign_data.forEach(campaignData => {
        const date = campaignData.date; // Extract the date (YYYY-MM-DD)
        if (campaignDailyTotals[date] !== undefined) {
          // Find the index for this date in the last 60 days
          const dayIndex = last60Days.indexOf(date);
          if (dayIndex !== -1) {
            campaignValues[dayIndex] += campaignData.impressions; // Sum the impressions for this date
          }
        }
      });


      let runningSumArray = [];
      let cumulativeSum = 0;

      campaignValues.forEach(value => {
        cumulativeSum += value;
        runningSumArray.push(cumulativeSum);
      });


      // Push the result for this campaign
      campaignsResult.push({
        label: campaign.name, // Assuming campaign has a 'name' field
        data: runningSumArray
      });
    });

    setCampaignsData(campaignsResult)
  }

  React.useEffect(() => {
    getCampaigns();
  }, [reload, clientId]);


  const handleSaveCampaign = async () => {
    try {
      const { id, daily_data, campaign_data, ...rest } = form;
      const { error } = id >= 0
        ? await supabase.from('campaigns').update(rest).eq('id', id)
        : await supabase.from('campaigns').insert({ ...rest, user_id: clientId });

      if (error) throw error;

      if (id >= 0 && daily_data.length > 0) {
        const { error } = await supabase.from('campaign_data').upsert(
          daily_data?.map(d => ({
            cid: id,
            date: d[0],
            impressions: d[1],
          })),
          { onConflict: ['cid', 'date'] } // Ensures uniqueness
        )
        if (error) throw error;
      }
      getCampaigns();
      clearForm();
    } catch (error) {
      alert(error.message);
      console.error('Error saving campaign:', error.message);
    }
  };

  const handleDeleteCampaignRow = async (id) => {
    try {
      const { error } = await supabase.from('campaign_data').delete().eq('id', id);
      if (error) throw error;
      setForm(prev => ({
        ...form,
        campaign_data: prev.campaign_data.filter(d => d.id !== id),
      }))
    } catch (error) {
      console.error('Error deleting campaign:', error.message);
    }
  };

  const handleDeleteCampaign = async (campaignId) => {
    const shouldRemove = window.confirm("Are you sure you want to delete?");
    if (!shouldRemove) return;
    try {
      const { error } = await supabase.from('campaigns').delete().eq('id', form.id);
      if (error) throw error;
      getCampaigns();
      clearForm();
    } catch (error) {
      console.error('Error deleting campaign:', error.message);
    }
  };

  const clearForm = () => {
    setForm({
      id: -1,
      name: '',
      cost: 0,
      impressions: 0,
      clicks: 0,
      daily_data: ''
    });
  }

  const handleSaveRegion = async () => {
    try {
      const { id, ...rest } = regform;
      const { error } = id >= 0
        ? await supabase.from('regions').update(rest).eq('id', id)
        : await supabase.from('regions').insert({ ...rest, user_id: clientId });

      if (error) throw error;
      getRegions();
      clearRegForm();
    } catch (error) {
      console.error('Error saving campaign:', error.message);
    }
  };

  const handleDeleteRegion = async () => {
    const shouldRemove = window.confirm("Are you sure you want to delete?");
    if (!shouldRemove) return;
    try {
      const { error } = await supabase.from('regions').delete().eq('id', regform.id);
      if (error) throw error;
      getRegions();
      clearRegForm();
    } catch (error) {
      console.error('Error deleting campaign:', error.message);
    }
  };

  const clearRegForm = () => {
    setRegForm({
      id: -1,
      city: '',
      region: '',
      cost: 0,
      impressions: 0,
      clicks: 0,
      conversions: 0
    });
  }

  const campaignSummary = getCampaignSummary(campaigns);
  const summary = getSummary(regions);
  return (
    <Box display='flex' flexDirection='column'>
      <Grid
        container
        spacing={4}
        justifyContent="space-evenly"
        alignItems="stretch"
      >
        <Grid item xs={12}>
          <Card variant='outlined' sx={{ background: theme.palette.grey['100'] }}>
            <Stack spacing={1} sx={{ p: 2, backgroundColor: theme.palette.grey['100'] }}>
              <Typography variant="h6" alignItems="center" color={theme.palette.grey['900']} mt={3}>
                Impressions Per Campaign
              </Typography>
              <LineChart
                xAxis={[{ scaleType: 'band', data: getLast60Days().toReversed() }]}
                series={campaignsData?.length > 0 ?
                  campaignsData?.map((campaign) => ({
                    data: campaign.data,
                    label: campaign.label,
                    showMark: false
                  }))
                  :
                  [{
                    data: [{ x: 0, y: 0 }],
                    label: 'No data',
                    showMark: false
                  }]}
                bezier
                height={400}
                lineStyle={({ seriesIndex }) => ({
                  stroke: theme.palette[seriesIndex % 3 === 0 ? 'primary' : seriesIndex % 3 === 1 ? 'secondary' : 'error'].main,
                  strokeWidth: 4,
                })}
              />
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined' sx={{ background: theme.palette.grey['100'] }}>
            <Stack spacing={1} sx={{ p: 2, backgroundColor: theme.palette.grey['100'] }}>
              <Typography variant="h6" alignItems="center" color={theme.palette.grey['900']} mt={3} fontWeight="bold">
                Regional Stats
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="region stats table">
                  <TableHead sx={{ backgroundColor: theme.palette.grey['800'] }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Region</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>City</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Cost</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Impression</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Clicks</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>CTR</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>CPC</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Conversion</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Conv. Rate</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>ROAS</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Total Conv. Value</TableCell>
                      {isAdmin && <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>ACTIONS</TableCell>}

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {regions?.map((row, index) => {
                      // Calculate CTR, CPC, and CPM for conditional styling
                      const ctr = calculateCTR(row.clicks, row.impressions);
                      const cpc = calculateCPC(row.cost, row.clicks);
                      const roas = calculateROAS(row.conversions, row.cost);

                      // Color scales
                      const ctrColor = 'transparent';
                      const cpcColor =  'transparent';

                      return (
                        <TableRow key={row.clicks + index} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.grey['200'] : theme.palette.grey['300'] }}>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{row.region}</TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{row.city}</TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>${formatNumber(row.cost.toFixed(2))}</TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{formatNumber(row.impressions)}</TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{formatNumber(row.clicks)}</TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center', backgroundColor: ctrColor }}>
                            {ctr}%
                          </TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center', backgroundColor: cpcColor }}>
                            ${formatNumber(cpc)}
                          </TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{formatNumber(row.conversions)}</TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>
                            {calculateConvRate(row.conversions, row.clicks)}%
                          </TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>
                            {roas}
                          </TableCell>
                          <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>${formatNumber((row.conversions * 10).toFixed(2))}</TableCell>
                          {isAdmin &&
                            <TableCell
                              onClick={() => setRegForm(row)}
                              sx={{
                                fontSize: '0.75rem',
                                padding: '6px 8px',
                                textAlign: 'center',
                                backgroundColor: theme.palette.grey['400']
                              }}
                            >
                              EDIT
                            </TableCell>
                          }
                        </TableRow>
                      );
                    })}
                    <TableRow sx={{ backgroundColor: theme.palette.grey['400'] }}>
                      <TableCell colSpan={2} sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>Total</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>${formatNumber(summary.totals.cost.toFixed(2))}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>{formatNumber(summary.totals.impressions)}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>{formatNumber(summary.totals.clicks)}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        {(summary.totals.impressions ? (summary.totals.clicks / summary.totals.impressions) * 100 : 0).toFixed(2)}%
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        ${formatNumber((summary.totals.clicks ? summary.totals.cost / summary.totals.clicks : 0).toFixed(2))}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        {formatNumber(summary.totals.conversions)}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        {(summary.totals.clicks ? (summary.totals.conversions / summary.totals.clicks) * 100 : 0).toFixed(2)}%
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        {(summary.totals.cost ? (summary.totals.conversions * 10 / summary.totals.cost) : 0).toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>${formatNumber((summary.totals.conversions * 10).toFixed(2))}</TableCell>
                      {isAdmin &&
                        <TableCell
                          onClick={() => {
                            clearRegForm();
                          }}
                          sx={{
                            fontSize: '0.75rem',
                            padding: '6px 8px',
                            textAlign: 'center',
                            backgroundColor: theme.palette.grey['400']
                          }}
                        >
                        </TableCell>
                      }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {isAdmin && <RegionForm onSave={handleSaveRegion} region={regform} onCancel={clearRegForm} onDelete={handleDeleteRegion} setRegion={setRegForm} />}
        </Grid>
        <Grid item xs={12}>
          <Card variant='outlined' sx={{ background: theme.palette.grey['100'] }}>
            <Stack spacing={1} sx={{ p: 2, backgroundColor: theme.palette.grey['100'] }}>
              <Typography variant="h6" alignItems="center" color={theme.palette.grey['900']} mt={3} fontWeight="bold">
                Campaign Stats
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="campaign stats table">
                  <TableHead sx={{ backgroundColor: theme.palette.grey['800'] }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Campaign</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Cost</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Impressions</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Clicks</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>CTR</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>CPC</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Avg. CPM</TableCell>
                      {isAdmin && <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>ACTIONS</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {campaigns?.map((row, index) => (
                      <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.grey['200'] : theme.palette.grey['300'] }}>
                        <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{row.name}</TableCell>
                        <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>${formatNumber(row.cost.toFixed(2))}</TableCell>
                        <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{formatNumber(row.impressions)}</TableCell>
                        <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{formatNumber(row.clicks)}</TableCell>
                        <TableCell sx={{
                          fontSize: '0.75rem',
                          padding: '6px 8px',
                          textAlign: 'center',
                        }}>
                          {calculateCTR(row.clicks, row.impressions)}%
                        </TableCell>
                        <TableCell sx={{
                          fontSize: '0.75rem',
                          padding: '6px 8px',
                          textAlign: 'center',
                        }}>
                          ${formatNumber(calculateCPC(row.cost, row.clicks))}
                        </TableCell>
                        <TableCell sx={{
                          fontSize: '0.75rem',
                          padding: '6px 8px',
                          textAlign: 'center',
                        }}>
                          ${formatNumber(calculateCPM(row.cost, row.impressions))}
                        </TableCell>
                        {isAdmin &&
                          <TableCell
                            onClick={() => setForm(row)}
                            sx={{
                              fontSize: '0.75rem',
                              padding: '6px 8px',
                              textAlign: 'center',
                              backgroundColor: theme.palette.grey['400']
                            }}
                          >
                            EDIT
                          </TableCell>
                        }
                      </TableRow>
                    ))}
                    <TableRow sx={{ backgroundColor: theme.palette.grey['400'] }}>
                      <TableCell colSpan={1} sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>Total</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>${formatNumber(campaignSummary.totals.cost.toFixed(2))}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>{formatNumber(campaignSummary.totals.impressions)}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>{formatNumber(campaignSummary.totals.clicks)}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        {(campaignSummary.totals.clicks / campaignSummary.totals.impressions * 100).toFixed(2)}%
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        ${(campaignSummary.totals.cost / campaignSummary.totals.clicks).toFixed(2)}
                      </TableCell>
                      <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', textAlign: 'center' }}>
                        ${(campaignSummary.totals.cost / campaignSummary.totals.impressions * 1000).toFixed(2)}
                      </TableCell>
                      {isAdmin &&
                        <TableCell
                          onClick={() => {
                            clearForm();
                          }}
                          sx={{
                            fontSize: '0.75rem',
                            padding: '6px 8px',
                            textAlign: 'center',
                            backgroundColor: theme.palette.grey['400']
                          }}
                        >
                        </TableCell>
                      }
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {isAdmin && <CampaignForm onSave={handleSaveCampaign} campaign={form} onCancel={clearForm} onDelete={handleDeleteCampaign} setCampaign={setForm}
            handleDeleteCampaignRow={handleDeleteCampaignRow} />}
        </Grid>
      </Grid>
    </Box>
  );
}

const CampaignForm = ({ campaign, onSave, onCancel, onDelete, setCampaign, handleDeleteCampaignRow }) => {
  const theme = useTheme();
  const setValue = (key) => (e) => setCampaign({ ...campaign, [key]: e.target.value });
  const setValueNum = (key) => (e) => setCampaign({ ...campaign, [key]: +(e.target.value.replace(/[^\d.-]+/g, '')) });
  return (
    <Card variant='outlined' sx={{ background: theme.palette.grey['100'] }}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant='h6'>Add/Edit Campaign</Typography>
          <TextField
            name='name'
            required
            size='small'
            label="Campaign Name"
            color='info'
            onChange={setValue('name')}
            value={campaign.name}
          />
          <TextField
            name='cost'
            required
            size='small'
            label="Cost"
            color='info'
            onChange={setValueNum('cost')}
            value={campaign.cost}
          />
          <TextField
            name='impressions'
            required
            size='small'
            label="Impressions"
            color='info'
            onChange={setValueNum('impressions')}
            value={campaign.impressions}
          />
          <TextField
            name='clicks'
            required
            size='small'
            label="Clicks"
            color='info'
            onChange={setValueNum('clicks')}
            value={campaign.clicks}
          />

          {campaign.id >= 0 &&
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="campaign stats table">
                <TableHead sx={{ backgroundColor: theme.palette.grey['800'] }}>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>Impressions</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', fontSize: '0.75rem', color: theme.palette.common.white, padding: '6px 8px', textAlign: 'center' }}>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {campaign?.campaign_data?.map((row, index) => (
                    <TableRow key={index} sx={{ backgroundColor: index % 2 === 0 ? theme.palette.grey['200'] : theme.palette.grey['300'] }}>
                      <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{row.date}</TableCell>
                      <TableCell sx={{ fontSize: '0.75rem', padding: '6px 8px', textAlign: 'center' }}>{formatNumber(row.impressions.toFixed(2))}</TableCell>
                      <TableCell
                        onClick={() => handleDeleteCampaignRow(row.id)}
                        sx={{
                          fontSize: '0.75rem',
                          padding: '6px 8px',
                          textAlign: 'center',
                          backgroundColor: theme.palette.grey['400']
                        }}
                      >
                        DELETE
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          {campaign.id >= 0 &&
            <CSVReader onFileLoaded={(data) => setCampaign({ ...campaign, daily_data: data })} />
          }
        </Stack>

        <Stack direction="row" spacing={1} mt={2}>
          <Button variant="contained" color="info" onClick={onSave}>Save</Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="outlined" color="error" onClick={onCancel}>CLEAR</Button>
          <Button variant="contained" color="error" onClick={onDelete}>DELETE</Button>
        </Stack>
      </CardContent>
    </Card>
  )
}

const RegionForm = ({ region, onSave, onCancel, onDelete, setRegion }) => {
  const theme = useTheme();
  const setValue = (key) => (e) => setRegion({ ...region, [key]: e.target.value });
  const setValueNum = (key) => (e) => setRegion({ ...region, [key]: +(e.target.value.replace(/[^\d.-]+/g, '')) });
  return (
    <Card variant='outlined' sx={{ background: theme.palette.grey['100'] }}>
      <CardContent>
        <Stack spacing={2}>
          <Typography variant='h6'>Add/Edit Region</Typography>
          <TextField
            name='name'
            required
            size='small'
            label="City"
            color='info'
            onChange={setValue('city')}
            value={region.city}
          />
          <TextField
            name='name'
            required
            size='small'
            label="Region"
            color='info'
            onChange={setValue('region')}
            value={region.region}
          />
          <TextField
            name='cost'
            required
            size='small'
            label="Cost"
            color='info'
            onChange={setValueNum('cost')}
            value={region.cost}
          />
          <TextField
            name='impressions'
            required
            size='small'
            label="Impressions"
            color='info'
            onChange={setValueNum('impressions')}
            value={region.impressions}
          />
          <TextField
            name='clicks'
            required
            size='small'
            label="Clicks"
            color='info'
            onChange={setValueNum('clicks')}
            value={region.clicks}
          />
          <TextField
            name='conversions'
            required
            size='small'
            label="Conversions"
            color='info'
            onChange={setValueNum('conversions')}
            value={region.conversions}
          />
        </Stack>

        <Stack direction="row" spacing={1} mt={2} sx={{ width: '100%' }}>
          <Button variant="contained" color="info" onClick={onSave}>Save</Button>
          <Box sx={{ flexGrow: 1 }} />
          <Button variant="outlined" color="error" onClick={onCancel}>CLEAR</Button>
          <Button variant="contained" color="error" onClick={onDelete}>DELETE</Button>
        </Stack>
      </CardContent>
    </Card>
  )
}