import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import {useUser} from '../../../hooks/context';
import { Link, useNavigate} from "react-router-dom";
import supabase from '../../../hooks/supabase';

function ResponsiveAppBar({ white, linearGradient }) {
  const navigate = useNavigate();
  const { user } = useUser();
  const theme = useTheme();
  const simplexColor = white ? theme.palette.grey['50'] : theme.palette.secondary.main;
  const bgColor = linearGradient ? 'linear-gradient(to bottom, rgba(0,0,0,0.6), rgba(0,0,0,0.4), rgba(0,0,0,0))' : 'transparent';

  return (
    <AppBar position="relative" sx={{ background: bgColor, boxShadow: 'none', my: 0, py: 0 }}>
      <Container maxWidth="xl" sx={{ py: 0, my: 0 }}>
        <Toolbar disableGutters sx={{ py: 3, height: '70px' }}>
          <Button
            variant='outline'
            disableElevation={true}
            disabled={true}
            sx={{
              "&:disabled": {
                color: '#fff'
              },
              display: {
                sm: 'flex',
                md: 'none',
                color: '#fff'
              }
            }}
          >
            <Typography variant='body1' sx={{ fontSize: { xs : '14px', md: '18px' } }}>
              Logout
            </Typography>
          </Button>
          <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' } }} />
          <Link to={`/dashboard/${user?.type === 'CLIENT' ? 'home' : user?.type?.toLowerCase()}`} style={{ textDecoration: 'none' }}>
            <Box display='flex' alignItems={'center'}>
              <Typography
                variant="h3"
                align='center'
                sx={{
                  display: 'flex',
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: simplexColor,
                  textDecoration: 'none',
                }}
              >
                simplex
              </Typography>
            </Box>
          </Link>
          <Box sx={{ flexGrow: 1, display: { sm: 'none', md: 'flex' } }} />
          <Box sx={{ flexGrow: 1, display: { sm: 'flex', md: 'none' } }} />
          <Button
            variant='contained'
            disableElevation={true}
            onClick={async () => {
              await supabase.auth.signOut();
              return navigate('/dashboard');
            }}
          >
          <Typography variant='body1' sx={{ fontSize: { xs: '14px', md: '18px' } }}>
              Logout
            </Typography>
          </Button>
        </Toolbar>
      </Container>
    </AppBar >
  );
}
export default ResponsiveAppBar;