import useTheme from '@mui/material/styles/useTheme';
import * as React from 'react';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import useMediaQuery from '@mui/material/useMediaQuery'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Charts from './Charts';
import Appbar from '../Appbar';
import Footer from '../../../components/Footer';
import InvoiceLine from '../../../components/Dashboard/InvoiceLine';
import { useUser } from '../../../hooks/context';
import supabase from '../../../hooks/supabase';
const isEmpty = (obj) => Object.keys(obj).length === 0;
export default function DashboardClientHome() {
  const { user } = useUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [locationClient, setLocationClient] = React.useState(location.state?.client);
  const [client, setClient] = React.useState();
  const isAdmin = user.type === 'ADMIN';
  const statusColor = client?.type === 'ADMIN'
    ? theme.palette.grey['900']
    : (client?.type === 'CLIENT'
      ? theme.palette.success.main
      : theme.palette.secondary.main
    );
  const isClientAdmin = client?.type === 'ADMIN';
  const isClient = client?.type === 'CLIENT';

  const [invoices, setInvoices] = React.useState(null);
  const [prevInvoices, setPrevInvoices] = React.useState(null);
  const [fetchError, setFetchError] = React.useState('');

  const [reload, setReload] = React.useState(0);
  const [customerId, setCustomerId] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState('');
  const [error, setError] = React.useState('');

  const getInvoices = async () => {
    setInvoices(null);
    fetch(`https://wd-simplex-media-group-server.fly.dev/user/invoices?id=${client?.customer_id}`)
      .then(async (res) => {
        return { status: res.status, json: await res.json() };
      })
      .then((data) => {
        if (data.status !== 200 || data.json.message !== "OK") {
          setFetchError(data.json.message);
        } else {
          setInvoices(data.json.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setFetchError('Failed to fetch invoices.');
      });
  };

  const handleCustomerId = async (e) => {
    setLoading(true);
    e.preventDefault();

    const { error, data } = await supabase.from('profiles')
      .update({
        customer_id: customerId
      })
      .eq('id', client.id)
      .select('*')
      .single();

    if (error || !data) {
      setError(error?.message);
      setSuccess('');
    } else {
      setClient(data);
      setError();
    }
    setLoading(false);
  }

  const changeType = async (type) => {
    setLoading(true);

    const { error, data } = await supabase.from('profiles')
      .update({
        type
      })
      .eq('id', client.id)
      .select('*')
      .single();

    if (error) {
      setError(error?.message);
      setSuccess('');
    } else {
      setClient(data);
      setError();
    }
    setLoading(false);
  }

  React.useEffect(() => {
    getInvoices();
  }, [user]);

  React.useEffect(() => {
    if (locationClient && !isEmpty(locationClient)) {
      setClient(locationClient);
    } else {
      setClient(user);
    }
  }, [user]);

  if (!locationClient && isAdmin) {
    return navigate('/dashboard', { reload: true });
  }

  if (!client) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        my={8}
      >
        <CircularProgress sx={{ color: '#d12f2f' }} />
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' sx={{ minHeight: '100vh' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Appbar />
        <Container maxWidth="md" sx={{ minHeight: '100vh' }}>
          {!isAdmin &&
            <Typography variant="body1" color={theme.palette.grey['900']} fontSize={35} fontWeight={100} mr={2} mb={4}>
              Welcome, {client.full_name}
            </Typography>
          }
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center' justifyContent='left'>
              <Typography variant="h3" color={theme.palette.grey['900']} mr={2}>
                {client.company_name}
              </Typography>
              <Typography variant="h6" fontWeight={400} color={statusColor}>
                {client.type}
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              {isAdmin &&
                <Link to={'https://dashboard.stripe.com/invoices'} target="_blank" style={{ textDecoration: 'none' }}>
                  <Button color="secondary" variant="contained" size='small' sx={{ mr: 0.5 }}>
                    NEW INVOICE
                  </Button>
                </Link>
              }
              <Button
                color="info"
                variant="outlined"
                size='small'
                onClick={() => {
                  setFetchError('');
                  getInvoices();
                  setReload(p => p + 1);
                }}>
                REFRESH
              </Button>
            </Box>
          </Box>


          {isClient &&
            <>
              <Typography variant="h6" mt={3} mb={1} alignItems='center' color={theme.palette.grey['900']} >Analytics </Typography>
              <Charts clientId={client?.id} reload={reload} isAdmin={isAdmin} />
            </>
          }
          <Typography variant="body1" align="center" fontWeight='bold' color={theme.palette.secondary.main} my={8}>
            {fetchError}
          </Typography>
          {isClient && !fetchError &&
            <>
              <Typography variant="h6" alignItems='center' color={theme.palette.grey['900']} mt={3} mb={1}>Outstanding Invoices</Typography>
              {!invoices &&
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  my={8}
                >
                  <CircularProgress sx={{ color: '#d12f2f' }} />
                </Box>
              }
              {invoices && invoices.map(invoice => <InvoiceLine key={invoice.id} invoice={invoice} outstanding={true} isAdmin={isAdmin} />)}
              {invoices && invoices.length === 0 && (
                <Typography variant="body1" my={2} align='center' color={theme.palette.grey['900']}>
                  All invoices resolved!
                </Typography>
              )}

              <Typography variant="h6" alignItems='center' color={theme.palette.grey['900']} mt={3} mb={1}>Previous Invoices</Typography>
              {!prevInvoices &&
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  my={8}
                >
                  <CircularProgress sx={{ color: '#d12f2f' }} />
                </Box>
              }
              {prevInvoices && prevInvoices.map(invoice => <InvoiceLine key={invoice.id} invoice={invoice} />)}
              {prevInvoices && prevInvoices.length === 0 && (
                <Typography variant="body1" my={2} align='center' color={theme.palette.grey['900']}>
                  No invoices found ...
                </Typography>
              )}
            </>
          }
          <Typography variant="h6" mt={3} alignItems='center' color={theme.palette.grey['900']} >Account Details </Typography>
          <Card variant='outlined' sx={{ background: theme.palette.grey['100'] }}>
            <CardContent>
              <Stack>
                {isAdmin &&
                  <>
                    <Link to={`https://dashboard.stripe.com/customers/${client.customer_id}`} target="_blank" style={{ textDecoration: 'none', width: '100%' }}>
                      <Box display='flex' alignItems='center'>
                        <Typography variant="body1" color={theme.palette.secondary.main}>
                          Stripe Profile
                        </Typography>
                        <ArrowOutwardIcon color='secondary' fontSize='small' />
                      </Box>
                    </Link>
                    <Typography variant="body1" align="left" color={theme.palette.grey['900']}>
                      <b>Stripe ID</b>: {!matches && <br />}{client.customer_id}
                    </Typography>
                  </>
                }
                <Typography variant="body1" align="left" color={theme.palette.grey['900']} >
                  <b>Full Name</b>: {!matches && <br />}{client.full_name}
                </Typography>
                <Typography variant="body1" align="left" color={theme.palette.grey['900']} >
                  <b>Email</b>: {!matches && <br />}{client.email}
                </Typography>
                {isAdmin && !isClientAdmin &&
                  <>
                    <Box display='flex' alignItems='center' justifyContent='center' sx={{ width: '100%' }}>
                      <Box flexGrow={1}>
                        <TextField
                          required
                          sx={{ my: 2, width: '100%' }}
                          label="Stripe Customer ID"
                          size='small'
                          color='info'
                          onChange={e => setCustomerId(e.target.value)}
                          value={customerId}
                          error={error}
                          helperText={error}
                        />
                      </Box>
                      <Button
                        color="success"
                        variant="contained"
                        sx={{ ml: 2, textTransform: 'none', color: '#FFF' }} onClick={handleCustomerId} disabled={loading}>
                        {!loading &&
                          <Typography variant="body2" align="center" color={theme.palette.grey['50']}>
                            Set Stripe ID
                          </Typography>
                        }
                        {loading && <CircularProgress sx={{ color: '#fff' }} size={30} />}
                      </Button>
                    </Box>
                    <Typography variant="body1" align="center" sx={{ fontWeight: '450', color: '#d12f2f' }}>{error}</Typography>
                    <Typography variant="body1" align="center" sx={{ fontWeight: '300' }}>{success}</Typography>
                  </>
                }
                <Box>
                  <Box my={2} />
                  <Divider />
                  <Box my={2} />
                  {isAdmin &&
                    <Box display='flex' alignItems='center' justifyContent='center'>
                      {client.type === 'REQUEST' &&
                        <Button
                          disabled={!client.customer_id}
                          color="success" variant="contained"
                          sx={{ textTransform: 'none', color: '#FFF' }}
                          onClick={() => changeType('CLIENT')}
                        >
                          Accept Client Request
                        </Button>
                      }
                      {client.type === 'REFUSED' &&
                        <Button color="success" variant="contained" sx={{ textTransform: 'none', color: '#FFF' }}
                          onClick={() => changeType('REQUEST')}>
                          Reinstate Account
                        </Button>
                      }
                      <Button color="secondary"
                        variant="contained" sx={{ ml: 2, textTransform: 'none' }}
                        onClick={() => changeType('REFUSED')}
                        disabled={client.type === 'REFUSED' || client.type === 'ADMIN'}>
                        Block Access
                      </Button>
                    </Box>
                  }
                  {!isAdmin &&
                    <Box display='flex' alignItems='center' justifyContent='center'>
                      <Button color="secondary" variant="outlined" sx={{ textTransform: 'none' }} onClick={() => navigate('/dashboard/reset_password', { state: { client: client } })}>
                        Change my password
                      </Button>
                    </Box>
                  }
                </Box>
              </Stack>
            </CardContent>
          </Card>
          <Box my={4} />
        </Container>
      </Box >
      <Footer />
    </Box >
  );
}