import React, { createContext, useContext, useState, useEffect } from 'react';
import supabase from './supabase';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [userProfile, setUserProfile] = useState({}); // Store the user profile

  useEffect(() => {
    // Get the current session if available
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session?.user) {
        fetchUserProfile(session.user.id); // Fetch user profile on initial session load
      } else {
        setUserProfile(null);
      }
    });

    // Listen to authentication state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session?.user) {
        fetchUserProfile(session.user.id); // Fetch user profile when session changes
      }else {
        setUserProfile(null);
      }
    });

    // Clean up subscription on component unmount
    return () => subscription.unsubscribe();
  }, []);

  // Function to fetch the user's profile
  const fetchUserProfile = async (userId) => {
    try {
      const { data, error } = await supabase
        .from('profiles') // Assuming 'profiles' is the table where user profiles are stored
        .select('*')
        .eq('id', userId)
        .single();

      if (error) throw error;

      setUserProfile(data); // Set the profile data to state
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  return (
    <UserContext.Provider value={{ session, user: userProfile }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the user context
export const useUser = () => useContext(UserContext);
